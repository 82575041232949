import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx";
import { graphql } from 'gatsby';
export const BlogPostQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    banner: file(relativePath: { eq: "post/demo-post/images/banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  BlogPostQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Hoy he decidido empezar a escritir con el objetivo de brindar un contenido de
valor para retribuir a la sociedad por lo mucho que me ha brindado como
individuo.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{3,5}",
        "{3,5}": true
      }}>{`function someDemo() {
  var test = 'Hello World!'
  console.log(test)
}

return () => <App />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      